<template>
    <LoadingSpinner :showLoading="loading" style="width: 100%;height:100%"/>
        <div v-if='!loading'>
            <div class='header'>
                <span class="p-text-bold pop-notes-title" >Trending Assets</span>
                <SelectButton v-model="range" :options="ranges" dataKey="value" class='date-range-selector'>
                    <template #option="slotProps">
                        {{slotProps.option.value }}
                    </template>
                </SelectButton>
            </div>
            <div class='type-card' v-for='security of securities' :key='security.securityId'>
                <MiniQuotesSimple component="Popular Assets" class='performance-type' :name="security.name" :change="security.priceData.change" :changepercent="security.priceData.changepercent" :icon="security.icon" />
            </div>
     </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MiniQuotesSimple from '../../components/QuoteMedia/MiniQuotesSimple';

import SelectButton from 'primevue/selectbutton';

import DataService from '../../service/DataService';

import SecurityFormatter from '../../common/SecurityFormatter';
import format from 'date-fns/format';
import add from 'date-fns/add';

export default {
    components: {
        SelectButton,
        LoadingSpinner,

        MiniQuotesSimple
    },
    data() {
        return {
            securities: [],
            range: {value: "1M"},
            ranges: [{ value: "1W"}, { value: "1M"}, {value: "1Y"}],
            loading: false,
            assetsRefetcher: false,
            priceRefetcher: false,
            justMounted: false,
        };
    },
    watch: {
        range() {
            this.fetchAssetsData().then(() => {
                this.fetchPriceChangeData().then(() => {
                    this.startPollingAssets();
                    this.startPollingPriceChange();
                })
            });
        }
    },

    mounted() {
        
        this.justMounted = true;
        this.fetchAssetsData().then(() => {
            this.fetchPriceChangeData().then(() => {
                this.startPollingAssets();
                this.startPollingPriceChange();
            })
        });
    },

    activated() {
        
        if( !this.justMounted ) {
            this.fetchAssetsData().then(() => {
                this.fetchPriceChangeData().then(() => {
                    this.startPollingAssets();
                    this.startPollingPriceChange();
                })
            });
        }
        else {
            this.justMounted = false;
        }
    },
    deactivated() {
       
        this.stopPollingAssets();
        this.stopPollingPriceChanges();
    },

    unmounted() {
        this.stopPollingAssets();
        this.stopPollingPriceChanges();
        
    },

    methods: {
        
        startPollingAssets() {
			if( this.assetsRefetcher ){
				clearInterval(this.assetsRefetcher);
			}
			this.assetsRefetcher = setInterval(()=> {
				this.fetchAssetsData();
			}, 60000);
		},
        startPollingPriceChange() {
			if( this.priceRefetcher ){
				clearInterval(this.priceRefetcher);
			}
			this.priceRefetcher = setInterval(()=> {
				this.fetchPriceChangeData();
			}, 5000);
		},

		stopPollingPriceChanges() {
			if( this.priceRefetcher ){
				clearInterval(this.priceRefetcher);
			}
		},
		stopPollingAssets() {
			if( this.assestsRefetcher ){
				clearInterval(this.assestsRefetcher);
			}
		},

        async fetchAssetsData() {
            this.loading = true;

            let startDate = new Date();
            let endDate = add(new Date(), {days: 1});

            if( this.range.value == '1M'){
                startDate = add(startDate, {months: -1});
               

            } else if( this.range.value == '1W'){
                startDate = add(startDate, {days: -7});
               

            }else {
                startDate = add(startDate, {days: -365});
            }
            await DataService.getTopMentionedEquities(5, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')).then(async (resp) => {
                
                if( resp.data.status == "success") {
                    this.securities = resp.data.securities.map((security) => {
                        return {
                            name: security.company,
                            exchangeSymbol: security.exchangeSymbol,
                            symbol: security.symbol,
                            priceData: {
                                change: 0.0,
                                changepercent: 0.0
                            },
                            icon: {
                                type: 'text',
                                value: security.symbol
                            }
                        }
                    });
                    
                }  else {
                console.log("error getting top secs ", resp.message);
                }
             });
        },
        async fetchPriceChangeData() {
            if( this.securities.length > 0 ) {
                await DataService.getSnapQuotes([...(this.securities.map(el => SecurityFormatter.getSymbolViaCdnExchanges(el)))].join()).then(resp => {
                        
                    let quoteData = resp.data.results.quote;
                    if( quoteData) {
                        this.securities.forEach( el => {
                            quoteData.forEach( q => {
                              if (q.pricedata) {
                                if( q.key.symbol === SecurityFormatter.getSymbolViaCdnExchanges(el)) {
                                    el.priceData.change = q.pricedata.change;
                                    el.priceData.changepercent = q.pricedata.changepercent;
                                }
                              }
                            });
                        });
                    }

                    this.loading = false;
                });
            }
            else {
                this.loading = false;
            }
        } 
    }

};
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.header .pop-notes-title {
    font-size: 24px;
    color: #32364e;
}
.header .date-range-selector {
    margin-left: auto;
}

::v-deep(.date-range-selector .p-button) {
    padding: 4px;
    border-radius: 4px;
    border: none;
    margin-right: 4px;
}
::v-deep(.date-range-selector .p-button:last-child) {
    margin-right: 0px;
}
::v-deep(.date-range-selector .p-button:not(.p-disabled):not(.p-highlight):hover) {
    background: #32364e;
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
}
::v-deep(.date-range-selector .p-button.p-highlight){
    background-color: #32364e;
    border-radius: 4px;
}
::v-deep(.date-range-selector .p-button:focus),
::v-deep(.date-range-selector .p-button.p-highlight:focus){
    box-shadow: none;
}

.feed-listing {
    border-top: 1px solid rgb(226, 226, 226);
}

/* ::v-deep(div.p-selectbutton.p-buttonset.p-component > div.p-button.p-component){
    padding: 5px;
    border: none;
} */


.type-card {
    margin-bottom: 16px;
    background: #F2F4FA;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.type-card:last-child {
    margin-bottom: 0px;
}

::v-deep(.type-card .min-quote .icon-container) {
    height: 40px;
    flex: 1 0 56px;
}

::v-deep(.type-card .min-quote .qmod-quote) {
    padding: 8px;
}
::v-deep(.type-card  .min-quote .qmod-quote .name-block) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
    
}
::v-deep(.type-card  .min-quote .qmod-quote .name-block .display-name) {
    font-size: 16px;
}
::v-deep(.type-card  .min-quote .qmod-quote .qmod-change-group) {
    font-size: 16px;
}


</style>